'use client';

import * as React from 'react';
import { Check, Settings, X } from 'lucide-react';

import { cn } from '@/lib/utils';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '@/components/ui/popover';
import { Button } from './button';
import {
  ignoreWhenFocused,
  useKeyboardShortcut
} from '@/lib/use-keyboard-shortcut';

interface Option {
  value: string;
  label: string;
  hidden: boolean;
  filterValues: string[];
}

interface ComboboxProps {
  options: Option[];
  multiSelect?: boolean;
  onCreateOption?: (newOptionLabel: string) => Promise<Option> | Option;
  children: React.ReactNode;
  selectedValues?: string[];
  onSelectedValuesChange?: (selectedValues: string[]) => void;
  onOpenSettings?: () => void;
}

export type ComboboxHandle = {
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export const Combobox = React.forwardRef<ComboboxHandle, ComboboxProps>(
  (
    {
      options,
      multiSelect = false,
      onCreateOption,
      children,
      selectedValues: controlledSelectedValues,
      onSelectedValuesChange,
      onOpenSettings
    },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);
    const [internalSelectedValues, setInternalSelectedValues] = React.useState<
      string[]
    >([]);
    const [searchTerm, setSearchTerm] = React.useState('');

    const isControlled = controlledSelectedValues !== undefined;
    const selectedValues = isControlled
      ? controlledSelectedValues
      : internalSelectedValues;

    const handleSelect = (currentValue: string) => {
      const newSelectedValues = selectedValues.includes(currentValue)
        ? selectedValues.filter((value) => value !== currentValue)
        : multiSelect
        ? [...selectedValues, currentValue]
        : [currentValue];

      if (isControlled && onSelectedValuesChange) {
        onSelectedValuesChange(newSelectedValues);
      } else {
        setInternalSelectedValues(newSelectedValues);
      }

      if (!multiSelect) {
        setOpen(false);
      }
    };

    const handleRemove = (valueToRemove: string) => {
      const newSelectedValues = selectedValues.filter(
        (value) => value !== valueToRemove
      );

      if (isControlled) {
        onSelectedValuesChange?.(newSelectedValues);
      } else {
        setInternalSelectedValues(newSelectedValues);
      }
    };

    const handleCreateNew = async () => {
      if (onCreateOption && searchTerm) {
        const newOption = await onCreateOption(searchTerm);
        setSearchTerm('');
        handleSelect(newOption.value);
      }
    };

    React.useImperativeHandle(ref, () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
      toggle: () => setOpen((prev) => !prev)
    }));

    const sortedOptions = React.useMemo(() => {
      return [...options].sort((a, b) => a.label.localeCompare(b.label));
    }, [options]);

    const interceptKeyboardShortcuts = React.useCallback(() => {
      if (open) return true;
    }, [open]);

    useKeyboardShortcut(ignoreWhenFocused(interceptKeyboardShortcuts), 100);

    return (
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>{children}</PopoverTrigger>
        <PopoverContent className='p-0 w-[480px]'>
          <Command>
            <div className='flex items-center'>
              <div className='flex flex-grow flex-wrap'>
                {selectedValues.map((value) => (
                  <span
                    key={value}
                    className='flex items-center h-6 px-2 text-xs text-gray-700 bg-gray-100 rounded w-fit m-1'
                  >
                    {options.find((option) => option.value === value)?.label}
                    <X
                      className='ml-1 h-3 w-3 cursor-pointer'
                      onClick={() => handleRemove(value)}
                    />
                  </span>
                ))}
              </div>
              {onOpenSettings && (
                <Button variant='ghost' className='m-1' onClick={() => {
                  console.log('open settings');
                  onOpenSettings();
                }}>
                  <Settings className='text-gray-500 cursor-pointer' />
                </Button>
              )}
            </div>
            <CommandInput
              placeholder='Search option...'
              value={searchTerm}
              onInput={(e) => setSearchTerm(e.currentTarget.value)}
              className='flex-grow'
            />
            <CommandList>
              <CommandEmpty>No option found.</CommandEmpty>
              <CommandGroup>
                {sortedOptions.filter((option) => { return option.hidden === false || selectedValues.includes(option.value) }).map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    keywords={option.filterValues || []}
                    onSelect={() => handleSelect(option.value)}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        selectedValues.includes(option.value)
                          ? 'opacity-100'
                          : 'opacity-0'
                      )}
                    />
                    {option.label}
                  </CommandItem>
                ))}
                {onCreateOption && searchTerm && (
                  <CommandItem onSelect={handleCreateNew}>
                    Create "{searchTerm}"
                  </CommandItem>
                )}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);

Combobox.displayName = 'Combobox';
