import { useRegion } from '@/lib/use-region';
import { Assignee } from '@/types';
import { useAuthInfo } from '@propelauth/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

const getAssigneeSortName = (a: Assignee) => {
    return a.assignee_name || a.user?.email || '';
}

class AssigneeWrapper {
  readonly id: string;
  private assignee: Assignee;

  constructor(assignee: Assignee) {
    this.id = assignee.id;
    this.assignee = assignee;
  }

  getFriendlyDisplayName(): string {
    return getAssigneeSortName(this.assignee);
  }

  get value(): string {
    return this.assignee.id;
  }

  get label(): string {
    return this.getFriendlyDisplayName();
  }

  get filterValues(): string[] {
    return [this.assignee.assignee_name, this.assignee.user?.email].filter(
      (a) => typeof a === 'string' && a.length
    ) as string[];
  }

  // Optionally, expose other Assignee properties if needed
  get assigneeName(): string {
    return this.assignee.assignee_name;
  }

  get userEmail(): string | undefined {
    return this.assignee.user?.email;
  }

  get hidden(): boolean {
    return this.assignee.hidden;
  }

  get _assignee(): Assignee {
    return this.assignee;
  }
}

const sortAssignees = (assignees: AssigneeWrapper[]) => {
  return assignees.sort((a, b) => a.getFriendlyDisplayName().localeCompare(b.getFriendlyDisplayName()))
}

export const useAssigneesQuery = () => {
  const { accessToken } = useAuthInfo();

  const { baseApiUrl } = useRegion();

  const assigneesQuery = useQuery<AssigneeWrapper[]>({
    queryKey: ['assignees'],
    queryFn: async () => {
      const response = await fetch(`${baseApiUrl}/assignees`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      const data: Assignee[] = await response.json();

      const wrappedData = data.map(assignee => new AssigneeWrapper(assignee));

      return sortAssignees(wrappedData);
    }
  });

  return { assigneesQuery };
};

export const useCreateAssigneeMutation = () => {
  const { accessToken } = useAuthInfo();
  const { baseApiUrl } = useRegion();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      const response = await fetch(
        `${baseApiUrl}/assignees`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ assignee_name: name })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to create assignee');
      }

      const data: Assignee = await response.json();

      const wrapped = new AssigneeWrapper(data);

      const assignees: AssigneeWrapper[] = queryClient.getQueryData(['assignees']) || [];

      assignees.push(wrapped);

      queryClient.setQueryData(['assignees'], sortAssignees(assignees));

      return wrapped;
    }
  });
};

export const useUpdateAssigneeMutation = () => {
  const { accessToken } = useAuthInfo();
  const { baseApiUrl } = useRegion();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (props: { id: string, name?: string, userId?: string, hidden?: boolean }) => {
      const response = await fetch(`${baseApiUrl}/assignees/${props.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ assignee_name: props.name, user_id: props.userId, hidden: props.hidden })
      });

      if (!response.ok) {
        throw new Error('Failed to create assignee');
      }

      const data: Assignee = await response.json();

      const wrapped = new AssigneeWrapper(data);

      const assignees: AssigneeWrapper[] =
        ((queryClient.getQueryData(['assignees']) || []) as AssigneeWrapper[]).filter(a => a.id !== props.id);

      assignees.push(wrapped);

      queryClient.setQueryData(['assignees'], sortAssignees(assignees));

      return wrapped;
    }
  });
};
