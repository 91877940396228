import { useRegion } from '@/lib/use-region';
import { User } from '@/types';
import { useAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';

export const useUsersQuery = () => {
  const { accessToken } = useAuthInfo();

  const { baseApiUrl } = useRegion();

  const usersQuery = useQuery<User[]>({
    queryKey: ['users'],
    queryFn: async () => {
      const response = await fetch(`${baseApiUrl}/users`, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });
      const data: User[] = await response.json();

      return data;
    }
  });

  return { usersQuery };
};
