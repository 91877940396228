import { useDDQState } from '@/components/ddq/use-ddq-state';
import { useRegion } from '@/lib/use-region';
import { AssigneeRequest } from '@/types';
import { useAuthInfo } from '@propelauth/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

type AssigneeRequestsResponse = { "assignee_requests": AssigneeRequest[], "empty_pairs": string[] };

export const useAssigneeRequestsQuery = ({ ddqId }: { ddqId: string }) => {
  const { accessToken } = useAuthInfo();
  const { baseApiUrl } = useRegion();

  const queryClient = useQueryClient();

  const { updateQuestionData } = useDDQState();

  return useQuery<Record<string, AssigneeRequest[]>, Error>({
    queryKey: ['assigneeRequests', ddqId],
    queryFn: async () => {
      const response = await fetch(
        `${baseApiUrl}/ddq/${ddqId}/assignee_requests`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch assignee requests');
      }

      const data: AssigneeRequestsResponse = await response.json();

      const withoutDeletes = data.assignee_requests.filter(ar => ar.deleted === false);

      const grouped = withoutDeletes.reduce<
        Record<string, AssigneeRequest[]>
      >((acc, request) => {
        const pairId = request.ddq_pair_id;
        if (!acc[pairId]) {
          acc[pairId] = [];
        }
        acc[pairId].push(request);
        return acc;
      }, {});

      for (const [ddqPairId, assigneeRequests] of Object.entries(grouped)) {
        queryClient.setQueryData(['assigneeRequests', ddqId, 'pair', ddqPairId], assigneeRequests);

        updateQuestionData(ddqPairId, { assignees: assigneeRequests.map(ar => ar.assignee_id) });
      }

      for (const empty of data.empty_pairs) {
        queryClient.setQueryData(
          ['assigneeRequests', ddqId, 'pair', empty],
          []
        );

        updateQuestionData(empty, {
          assignees: []
        });
      }

      return grouped;
    },
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchOnReconnect: true
  });
};


export const useUpdateAssignessQuery = ({ ddqId, ddqPairId }: { ddqId: string, ddqPairId: string }) => {
  const { accessToken } = useAuthInfo();
  const { baseApiUrl } = useRegion();

  const queryClient = useQueryClient();

  const { updateQuestionData } = useDDQState();

  return useMutation({
    mutationFn: async ({ assigneeIds }: { assigneeIds: string[] }) => {
      const response = await fetch(
        `${baseApiUrl}/pair/${ddqPairId}/assignees`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ assignee_ids: assigneeIds })
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update assignee requests');
      }

      const data: AssigneeRequest[] = await response.json();

      const withoutDeletes = data.filter(ar => ar.deleted === false)

      queryClient.setQueryData(['assigneeRequests', ddqId, 'pair', ddqPairId], withoutDeletes);

      updateQuestionData(ddqPairId, {
        assignees: withoutDeletes.map(ar => ar.assignee_id)
      });

      return data
    }
  });
}
