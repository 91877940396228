import React, { useRef, useState } from 'react';
import { ignoreWhenFocused, useKeyboardShortcut } from '@/lib/use-keyboard-shortcut';
import { Combobox, ComboboxHandle } from '@/components/ui/combobox';
import { Button } from '@/components/ui/button';
import { ShortcutKey } from '@/components/minimal-tiptap/components/shortcut-key';
import { useCreateAssigneeMutation } from '@/queries/assignees';
import { AssigneesModal } from './assignees-modal';

interface AssignProps {
  emailsAndAliases: { value: string; label: string; filterValues: string[]; hidden: boolean; }[];
  onSelect: (selected: string[]) => void;
  selectedValues: string[];
  onCreateNewOption?: (newOption: { value: string; label: string; filterValues: string[] }) => void;
}

const Assign: React.FC<AssignProps> = ({ emailsAndAliases, onSelect, selectedValues, onCreateNewOption }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const comboboxRef = useRef<ComboboxHandle>(null);

  const toggleMenu = ignoreWhenFocused((event: KeyboardEvent) => {
    if (event.key === 'a' || event.key === '@') {
      setTimeout(() => {
        comboboxRef.current?.open();
      }, 1);
    }
  });

  useKeyboardShortcut(toggleMenu);

  const createAssigneeMutation = useCreateAssigneeMutation();

  const handleCreateOption = async (newOptionLabel: string) => {
    const newAssignee = await createAssigneeMutation.mutateAsync({ name: newOptionLabel })

    if (onCreateNewOption) onCreateNewOption(newAssignee);
    
    return newAssignee;
  };

  const onOpenSettings = () => { setModalOpen(true) }
  const onCloseSettings = () => { setModalOpen(false) }

  return (
    <div className="relative">
      <AssigneesModal isOpen={modalOpen} onClose={onCloseSettings} />
      <Combobox
        ref={comboboxRef}
        options={emailsAndAliases}
        multiSelect={true}
        onCreateOption={handleCreateOption}
        selectedValues={selectedValues}
        onSelectedValuesChange={onSelect}
        onOpenSettings={onOpenSettings}
      >
        <Button variant="ghost">
          Assign <ShortcutKey keys={['a']} />
        </Button>
      </Combobox>
    </div>
  );
};

export default Assign;
