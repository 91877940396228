import { useDDQState } from '@/components/ddq/use-ddq-state';
import { useRegion } from '@/lib/use-region';
import { useAuthInfo } from '@propelauth/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

export const useSplitMutation = () => {
  // Get auth and region info from our hooks
  const { accessToken } = useAuthInfo();
  const { baseApiUrl } = useRegion();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      pairId
    }: {
      ddqId: string;
      pairId: string;
    }) => {
      const response = await fetch(`${baseApiUrl}/pair/${pairId}/split`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
        // For splitting we don’t need extra payload – adjust if necessary
        body: JSON.stringify({})
      });
      if (!response.ok) {
        throw new Error('Split operation failed');
      }
      return response.json();
    },
    onSuccess: (_, { ddqId }) => {
      queryClient.invalidateQueries({ queryKey: ['ddqs', ddqId]})
    }
  });
};

export const useMergeMutation = () => {
  // Get auth and region info from our hooks
  const { accessToken } = useAuthInfo();
  const { baseApiUrl } = useRegion();
  const queryClient = useQueryClient();
  const { setSelectedQuestion } = useDDQState();

  return useMutation(
    {
      mutationFn: async ({
        currentPairId,
        previousPairId
      }: {
        ddqId: string;
        currentPairId: string;
        previousPairId: string;
      }) => {
        // Call the merge endpoint with the current pair id and send the previous pair id as the source for merging.
        const response = await fetch(
          `${baseApiUrl}/pair/${previousPairId}/merge/${currentPairId}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${accessToken}`
            },
            body: JSON.stringify({ mergeFrom: previousPairId })
          }
        );
        if (!response.ok) {
          throw new Error('Merge operation failed');
        }
        return response.json();
      },
      onSuccess: (_, { ddqId, previousPairId }) => {
        queryClient.invalidateQueries({ queryKey: ['ddqs', ddqId] });
        setSelectedQuestion(previousPairId);
      }
    }
  );
};
