import { captureEvent, runTimerInMs } from '@/lib/analytics';
import { useRegion } from '@/lib/use-region';
import { AnalysisResponse, SimilarPair } from '@/types';
import { useAuthInfo } from '@propelauth/react';
import { useQuery } from '@tanstack/react-query';

const calculateStats = (ddqFormat: AnalysisResponse) => {
  // Num of questions
  const numberOfQuestions = ddqFormat.similar_pairs.length;
  // Avg answers per question
  const totalAnswers = ddqFormat.similar_pairs.reduce(
    (acc, pair) => acc + pair[1].length,
    0
  );
  const avgAnswersPerQuestion = totalAnswers / numberOfQuestions;
  // Count the number of questions with zero answers
  const questionsWithZeroAnswers = ddqFormat.similar_pairs.filter(
    (pair) => pair[1].length === 0
  ).length;
  // Calculate the proportion of questions with zero answers
  const proportionWithZeroAnswers =
    questionsWithZeroAnswers / numberOfQuestions;
  // Count the number of questions with exactly one answer
  const questionsWithOneAnswer = ddqFormat.similar_pairs.filter(
    (pair) => pair[1].length === 1
  ).length;
  // Calculate the proportion of questions with exactly one answer
  const proportionWithOneAnswer = questionsWithOneAnswer / numberOfQuestions;
  // Count the number of questions with 2+ answers
  const questionsWithTwoOrMoreAnswers = ddqFormat.similar_pairs.filter(
    (pair) => pair[1].length >= 2
  ).length;
  // Calculate the proportion of questions with two or more answers
  const proportionWithTwoOrMoreAnswers =
    questionsWithTwoOrMoreAnswers / numberOfQuestions;

  return {
    numberOfQuestions,
    totalAnswers,
    avgAnswersPerQuestion,
    questionsWithZeroAnswers,
    proportionWithZeroAnswers,
    questionsWithOneAnswer,
    proportionWithOneAnswer,
    questionsWithTwoOrMoreAnswers,
    proportionWithTwoOrMoreAnswers
  };
};

export const useAnalysisQuery = ({
  ddqId,
  isBlank = false,
  process
}: {
  ddqId: string;
  isBlank: boolean;
  process: (a: { ddqPairs: SimilarPair[] }) => Promise<void>;
}) => {
  const { accessToken } = useAuthInfo();

  const { baseApiUrl } = useRegion();

  const analysisQuery = useQuery<
    AnalysisResponse & { stats: ReturnType<typeof calculateStats> }
  >({
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    refetchOnReconnect: true,
    queryKey: ['ddqs', ddqId, `analysis${isBlank ? '-blank' : ''}`],
    queryFn: async () => {
      const timer = runTimerInMs();
      try {
        captureEvent('loadingAnalysis', {
          id: ddqId
        });

        const response = await fetch(
          `${baseApiUrl}/ddq/${ddqId}/analyze${isBlank ? '?blank=true' : ''}`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          }
        );

        if (!response.ok) {
          throw new Error('Failed to analyze ddq');
        }

        const analysisResponse = await response.json();
        const ddqFormat: AnalysisResponse = {
          ...analysisResponse.ddq,
          similar_pairs: analysisResponse.similar_pairs
        };

        isBlank === false && process({ ddqPairs: ddqFormat.similar_pairs });

        const stats = calculateStats(ddqFormat);

        console.log(
          `Number of questions in analysis: ${stats.numberOfQuestions}`
        );
        console.log(
          `Average number of answers per question: ${stats.avgAnswersPerQuestion}`
        );
        console.log(
          `Proportion of questions with zero answers: ${stats.proportionWithZeroAnswers}`
        );
        console.log(
          `Number of questions with zero answers: ${stats.questionsWithZeroAnswers}`
        );
        console.log(
          `Proportion of questions with exactly one answer: ${stats.proportionWithOneAnswer}`
        );
        console.log(
          `Number of questions with exactly one answer: ${stats.questionsWithOneAnswer}`
        );
        console.log(
          `Proportion of questions with two or more answers: ${stats.proportionWithTwoOrMoreAnswers}`
        );
        console.log(
          `Number of questions with two or more answers: ${stats.questionsWithTwoOrMoreAnswers}`
        );

        const elapsed = timer();

        captureEvent('loadingAnalysisSuccess', {
          id: ddqFormat.id,
          friendly_name: ddqFormat.friendly_name,
          approved_date: ddqFormat.approved_date,
          upload_name: ddqFormat.upload_name,
          loadTime: elapsed,
          ...stats
        });

        return { ...ddqFormat, stats };
      } catch (error) {
        const elapsed = timer();

        captureEvent('loadingAnalysisFailure', {
          id: ddqId,
          error: String(error),
          loadTime: elapsed
        });

        throw error;
      }
    }
  });

  return { analysisQuery };
};
