import React, { useState } from 'react';
import {
  useAssigneesQuery,
  useUpdateAssigneeMutation
} from '@/queries/assignees';
import { Assignee } from '@/types';
import { useUsersQuery } from '@/queries/users';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/ui/table';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import { Checkbox } from '@/components/ui/checkbox';
import { Button } from '@/components/ui/button';
import { Loader2 } from 'lucide-react';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '@/components/ui/dialog';

export const AssigneesModal: React.FC<{ isOpen: boolean; onClose: () => void }> = ({
  isOpen,
  onClose
}) => {
  const { assigneesQuery } = useAssigneesQuery();
  const {
    usersQuery: { data: users = [] }
  } = useUsersQuery();

  return (
    <Dialog open={isOpen}>
      <DialogContent className='min-w-[960px]'>
        <DialogHeader>
          <DialogTitle>Manage Assignees</DialogTitle>
          <DialogClose />
        </DialogHeader>
        {assigneesQuery.isLoading ? (
          <div className='flex justify-center'>
            <Loader2 className='w-6 h-6 animate-spin' />
          </div>
        ) : assigneesQuery.error ? (
          <p className='text-red-500'>Error loading assignees.</p>
        ) : (
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>User Association</TableHead>
                <TableHead>Hidden</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {(assigneesQuery.data || []).map((assignee) => (
                <AssigneeRow
                  key={assignee.id}
                  assignee={assignee._assignee}
                  users={users}
                />
              ))}
            </TableBody>
          </Table>
        )}
        <DialogFooter>
        <Button onClick={onClose} variant='secondary'>
          Close
        </Button>
      </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

/**
 * A separate component to handle editing a single assignee's row.
 * It maintains its own local state and references the update mutation.
 */

function AssigneeRow({
  assignee,
  users
}: {
  assignee: Assignee;
  users: Array<{ id: string; email: string }>;
}) {
  const [editing, setEditing] = useState(false);
  const [name, setName] = useState(assignee.assignee_name);
  const [userId, setUserId] = useState(assignee.user?.id || '');
  const [hidden, setHidden] = useState(assignee.hidden || false);

  const updateAssigneeMutation = useUpdateAssigneeMutation();

  const handleDoubleClick = () => {
    if (!editing) setEditing(true);
  };

  const handleCancel = () => {
    // revert changes on cancel
    setName(assignee.assignee_name);
    setUserId(assignee.user?.id || '');
    setHidden(assignee.hidden || false);
    setEditing(false);
  };

  const handleSave = () => {
    updateAssigneeMutation.mutate(
      {
        id: assignee.id,
        name,
        userId: userId || undefined,
        hidden
      },
      {
        onSuccess: () => {
          // close edit mode on success
          setEditing(false);
        }
      }
    );
  };

  return (
    <TableRow key={assignee.id}>
      {/* Name */}
      <TableCell
        className={!editing ? 'cursor-pointer' : ''}
        onDoubleClick={handleDoubleClick}
        title='Double-click to edit'
      >
        {editing ? (
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        ) : (
          assignee.assignee_name
        )}
      </TableCell>

      {/* User Association */}
      <TableCell
        className={!editing ? 'cursor-pointer' : ''}
        onDoubleClick={handleDoubleClick}
        title='Double-click to edit'
      >
        {editing ? (
          <Select value={userId} onValueChange={(val) => setUserId(val)}>
            <SelectTrigger>
              <SelectValue placeholder='Select User' />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel>Users</SelectLabel>
                {users.map((user) => (
                  <SelectItem key={user.id} value={user.id}>
                    {user.email}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        ) : (
          assignee.user?.email || 'Unassigned'
        )}
      </TableCell>

      {/* Hidden */}
      <TableCell
        className={`flex justify-center ${!editing ? 'cursor-pointer' : ''}`}
        onDoubleClick={handleDoubleClick}
        title='Double-click to edit'
      >
        {editing ? (
          <Checkbox
            checked={hidden}
            onCheckedChange={(checked) => setHidden(!!checked)}
          />
        ) : assignee.hidden ? (
          <Checkbox checked disabled />
        ) : (
          <Checkbox disabled />
        )}
      </TableCell>

      {/* Actions */}
      <TableCell onDoubleClick={handleDoubleClick} >
        {editing ? (
          <div className='flex space-x-2'>
            <Button
              onClick={handleSave}
              disabled={updateAssigneeMutation.isPending}
              variant='default'
            >
              {updateAssigneeMutation.isPending ? (
                <Loader2 className='w-4 h-4 animate-spin' />
              ) : (
                'Save'
              )}
            </Button>
            <Button onClick={handleCancel} variant='ghost'>
              Cancel
            </Button>
          </div>
        ) : (
          <div className='text-sm text-gray-500'>Double-click to edit</div>
        )}
      </TableCell>
    </TableRow>
  );
}
