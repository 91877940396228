import { useAssigneesQuery } from '@/queries/assignees';

export function useProfilesState() {
  const { assigneesQuery: { data } } = useAssigneesQuery();

  return {
    profiles: [...(data || [])]
  };
}

