import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';

import './markdown.css';
import { useRegion } from '@/lib/use-region';

const createUrlTransform = (baseApiUrl: string, ddqId: string) => {
  return (url: string) => {
    const mediaPattern = /^media\/(.+\..+)$/;
    const match = url.match(mediaPattern);
    if (match) {
      return `${baseApiUrl}/ddq/${ddqId}/media/${match[1]}`;
    }
    return url;
  };
};

const BaseMarkdown: React.ComponentType<{
  className?: string;
  content: string;
  ddqId?: string;
  disableProse?: boolean;
  enableImageFiltering?: boolean;
}> = ({ className, content, ddqId, disableProse = false, enableImageFiltering = false }) => {
  const { baseApiUrl } = useRegion();

  if (typeof content !== 'string') return <></>;

  let sanitizedContent = content;

  // Catch the case where markdown code block is created.
  if (content.startsWith('```markdown') && content.endsWith('```')) {
    sanitizedContent = sanitizedContent.slice(0, sanitizedContent.length - 3);
    sanitizedContent = sanitizedContent.slice(11);
  }

  let urlTransform = undefined;
  if (ddqId) {
    urlTransform = createUrlTransform(baseApiUrl, ddqId);
  }

  const components = enableImageFiltering ? { img: () => null } : {};

  return (
    <ReactMarkdown
      className={`${disableProse ? '' : 'prose'} gg-markdown ${className}`}
      remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
      components={components}
      rehypePlugins={[
        rehypeRaw,
        [
          rehypeSanitize,
          {
            ...defaultSchema,
            attributes: { ...defaultSchema.attributes, span: ['className'] },
            tagNames: [...(defaultSchema.tagNames || []).filter(tag => tag !== 'blockquote' && tag !== 'code' && tag !== 'pre'), 'mark']
          }
        ]
      ]}
      urlTransform={urlTransform}
    >
      {sanitizedContent}
    </ReactMarkdown>
  );
};

export const Markdown = React.memo(BaseMarkdown);
